import axios from 'axios'; 
import Swal from 'sweetalert2';

axios.defaults.baseURL = window.location.protocol+"//"+window.location.host+"/api";
axios.defaults.headers.common['Authorization'] = "Bearer " +window.localStorage.getItem("jwt");

axios.interceptors.response.use((response) => {

	
	
	console.log(response?.data?.is_active)
	if(response?.data?.is_active)
		return response;

	
	if (localStorage.getItem("user_data")) {
		localStorage.clear();
		sessionStorage.clear();
		Swal.fire({
			title: 'Error!',
			text: "Unauthorized access.",
			icon: 'warning', 
		})
		window.location.href='#/pages/login';
		throw error;
	}
	else {
		window.location.href='#/pages/login';
	}

	
	
}, (error) => {
	console.error(error)
	if(axios.isCancel(error)) {
		// if API request is cancelled, do nothing
	}
	else {
		Swal.fire({
			title: 'Error!',
			text: "Ooops! Something went wrong. Please contact your administrator.",
			icon: 'warning', 
		})
		  throw error;
	}
  	
});

// axios.interceptors.response.use((response) => response , (error) => {
//   	Swal.fire({
// 		title: 'Error!',
// 		text: "Ooops! Something went wrong. Please contact your administrator.",
// 		icon: 'warning', 
// 	})
//   	throw error;
// });


export default axios;